import Vue from 'vue';
import moment from 'moment-mini';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';

Vue.config.productionTip = false;
moment.locale('de', {
    weekdays: 'Sonntag_Montag_Dienstag_Mittwoch_Donnerstag_Freitag_Samstag'.split('_'),
});
new Vue({
    router,
    store,
    vuetify,
    render: (h) => h(App),
}).$mount('#app');
