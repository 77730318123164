import axios from 'axios';
import { LicensingServiceConfig } from '@/assets/js/services/licensing/LicensingServiceConfig';
import GetLicenceInformationResponseEntry from '@/assets/js/models/GetLicenseInformationResponseEntry';

export default class LicensingService {

    private config: LicensingServiceConfig;

    constructor(config: LicensingServiceConfig) {
        this.config = config;
    }

    public async getLicenseInfo(): Promise<GetLicenceInformationResponseEntry> {
        const response = await axios.post(`${this.config.serviceUrl()}/Authentication/getLicenceInformation`);
        return response.data.getLicenceInformation_response;
    }
}
