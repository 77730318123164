import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Home from '../views/Home.vue';
import About from '../views/About.vue';
import StartApp from "@/views/StartApp.vue";


Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
    {
        path: '/',
        name: 'Home',
        component: Home,
        meta: {
            loginRequired: true
        }
    },
    {
        path: '/About',
        name: 'About',
        component: About,
        meta: {
            loginRequired: true
        }
    },
    {
        path: '/Start',
        name: 'Start',
        component: StartApp,
        meta: {
            loginRequired: false
        }
    },
];

const router = new VueRouter({
    mode: 'history',
    routes,
});

export default router;

