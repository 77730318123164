import { AuthService, CloudflareService } from '@aprocon-software/aprocon-auth-util';
import AppsService from '@/assets/js/services/apps/AppsService';
import LicensingService from '@/assets/js/services/licensing/LicensingService';

export default class Services {

    public static appId: string = process.env.VUE_APP_APPID || '';
    public static serviceUrl: string|null = null;
    public static authToken: string|null = null;
    public static userId: string|null = null;
    public static branchId: string|null = null;

    /**
     * Cloudflare Webservice to determine the users ip address
     */
    public static Cloudflare = new CloudflareService();

    /**
     * APROCON Webservice to handle authorization
     */
    public static Auth = new AuthService({
        appId: Services.appId,
        serviceUrl: () => Services.serviceUrl || '',
        ip: () => Services.Cloudflare.getIp(),
        sid: () => Services.authToken || '',
    });

    public static Apps = new AppsService({
        appId: Services.appId,
        serviceUrl: () => Services.serviceUrl || '',
        ip: () => Services.Cloudflare.getIp(),
        sid: () => Services.authToken || '',
        userId: () => Services.userId || '' });

    public static Licensing = new LicensingService({ appId: Services.appId,
        serviceUrl: () => Services.serviceUrl || '',
        ip: () => Services.Cloudflare.getIp(),
        sid: () => Services.authToken || '' });
}
