import Vue from 'vue';
import Vuex from 'vuex';

import VuexPersistence from 'vuex-persist';

import Auth from './Auth';

Vue.use(Vuex);

const vuexLocal = new VuexPersistence<any>({
    modules: ['Auth'],
    storage: window.localStorage,
    key: process.env.VUE_APP_LOCALSTORAGE_KEY,
});

const store = new Vuex.Store({
    state: {},
    mutations: {},
    actions: {},
    modules: {
        Auth,
    },
    plugins: [
        vuexLocal.plugin,
    ],
});
export default store;
export const useStore = () => store
