import { render, staticRenderFns } from "./ServiceAbout.vue?vue&type=template&id=7a6ffcc9&scoped=true&"
import script from "./ServiceAbout.vue?vue&type=script&lang=ts&"
export * from "./ServiceAbout.vue?vue&type=script&lang=ts&"
import style0 from "./ServiceAbout.vue?vue&type=style&index=0&id=7a6ffcc9&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7a6ffcc9",
  null
  
)

export default component.exports