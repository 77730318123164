import axios from 'axios';
import { AppsServiceConfig } from '@/assets/js/services/apps/AppsServiceConfig';
import ApplicationManifest from '@/assets/js/models/ApplicationManifest';
import ApplicationIcon from '@/assets/js/models/ApplicationIcon';
import LicensedModule from '@/assets/js/models/LicensedModule';
import store from "@/store";

export default class AppsService {

    private config: AppsServiceConfig;

    constructor(config: AppsServiceConfig) {
        this.config = config;
    }
    public async getBranches(userId:number): Promise<{ Nummer: number, Name: string }[]> {
        const response = await axios.post(`${this.config.serviceUrl()}/Authentication/Tbl_Filialen`, {UserID: userId});
        return response.data.Tbl_Filialen_response.Tbl_Filialen;
    }
    public async getApplications(branchId:number) : Promise<LicensedModule[]> {
        // const apps: Promise<ApplicationManifest>[] = [];
        const response = await axios.post(`${this.config.serviceUrl()}/Authentication/GetLicensedModules`, { UserID: this.config.userId(), Filiale: branchId });

        // response.data.getLicensedModules_response.LicensedModules.push({
        //     'APPID': 'APRO.POS',
        //     'BASEURL': 'https://192.168.178.33:8085',
        //     'LICENCEEXPIRYDATE':
        //     '31.12.2022',
        //     'MANIFEST': {
        //         'TITLE': 'POS.Manager Local',
        //         'HEADER': 'Verwaltung für Kundenkarten, Gutscheine, Bonuskarten und Mitarbeiterkarten',
        //         'DETAILS': 'neue Karten ausgeben, Karten sperren, Buchungen prüfen und stornieren, Kundenadressen bearbeiten',
        //         'ICON_SVG': '',
        //         'SELFHOST': false,
        //     },
        // });
        // modules.data.getLicensedModules_response.LicensedModules.push({
        //     'APPID': 'APRO.CARD',
        //     'BASEURL':
        //         'https://diarystix.com/aprocon/gutscheinadmin',
        //     'LICENCEEXPIRYDATE':
        //         '31.12.2022',
        // });
        const apps = response.data.getLicensedModules_response.LicensedModules;
        // modules.data.getLicensedModules_response.LicensedModules.forEach((lm:any) => {
        //     if (lm.BASEURL) {
        //         if (lm.BASEURL.endsWith('/')) {
        //             lm.BASEURL = lm.BASEURL.substr(0, lm.BASEURL.length - 1);
        //         }
        //         apps.push(AppsService.getApplication(lm.BASEURL, lm.LICENCEEXPIRYDATE));
        //     }
        // });
        // // apps.push(AppsService.getApplication('http://192.168.178.33:8082', '2021-12-31'));
        // // apps.push(AppsService.getApplication('http://192.168.178.33:8082', '31.12.2021'));
        // return Promise.all(apps);
        // apps.push({
        //     'APPID': 'APRO.ORDER',
        //     'BASEURL': 'https://ordermanager-dev.aprocon.de',
        //     'LICENCEEXPIRYDATE': '31.12.2023',
        //     'MANIFEST': {
        //         'TITLE': 'ORDER-MANAGER',
        //         'HEADER': 'Header',
        //         'DETAILS': 'Details',
        //         'ICON_SVG': "<?xml version=\"1.0\" encoding=\"UTF-8\"?>\n<!DOCTYPE svg PUBLIC \"-//W3C//DTD SVG 1.1//EN\" \"http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd\">\n<svg version=\"1.1\" id=\"Layer_1\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" x=\"0px\" y=\"0px\" width=\"32px\" height=\"32px\" viewBox=\"0 0 32 32\" enable-background=\"new 0 0 32 32\" xml:space=\"preserve\">\n  <g id=\"icon\">\n    <path d=\"M28,32H2V0h26V32zM4,30h22V2H4V30z\" fill=\"#3C3C3C\"/>\n    <path d=\"M24,12h-6v-2h6V12zM16,12H6v-2h10V12z\" fill=\"#3C3C3C\"/>\n    <path d=\"M24,16h-6v-2h6V16zM16,16H6v-2h10V16z\" fill=\"#3C3C3C\"/>\n    <path d=\"M24,20h-6v-2h6V20zM16,20H6v-2h10V20z\" fill=\"#3C3C3C\"/>\n    <rect x=\"6\" y=\"4\" width=\"18\" height=\"3\" fill=\"#3C3C3C\"/>\n    <polygon points=\"17,28.414 13.293,24.707 14.707,23.293 17,25.586 21.293,21.293 22.707,22.707\" fill=\"#3C3C3C\" stroke-linejoin=\"miter\"/>\n  <\/g>\n<\/svg>\n",
        //         'SELFHOST': false,
        //     },
        // });
        // apps.push({
        //     'APPID': 'APRO.POSMANAGER',
        //     'BASEURL': 'https://192.168.178.33:8087',
        //     'LICENCEEXPIRYDATE': '31.12.2022',
        //     'MANIFEST': {
        //         'TITLE': 'GUTSCHEIN-VERWALTUNG',
        //         'HEADER': 'Verwaltung für Kundenkarten, Gutscheine, Bonuskarten und Mitarbeiterkarten',
        //         'DETAILS': 'neue Karten ausgeben, Karten sperren, Buchungen prüfen und stornieren, Kundenadressen bearbeiten',
        //         'ICON_SVG': '',
        //         'SELFHOST': false,
        //     },
        // });
        // apps.push({
        //     'APPID': 'APRO.FBW',
        //     'BASEURL': 'http://localhost:8099/FBW/',
        //     'LICENCEEXPIRYDATE': '31.12.2022',
        //     'MANIFEST': {
        //         'TITLE': 'FBW',
        //         'HEADER': 'Filialbestellungen',
        //         'DETAILS': 'Bla',
        //         'ICON_SVG': '',
        //         'SELFHOST': true,
        //     },
        // });

        return apps;
    }
    private static async getApplicationManifest(baseUrl: string) : Promise<ApplicationManifest|null> {
        return new Promise((resolve) => {
            const xhr = new XMLHttpRequest();
            xhr.open('GET', baseUrl);
            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        const result = <ApplicationManifest>JSON.parse(xhr.responseText);
                        resolve(result);
                    }
                    resolve(null);
                }
            };
            xhr.send();
        });
    }
    private static async getApplication(baseUrl: string, expirationDate:string) : Promise<ApplicationManifest> {
        const url = `${baseUrl}/manifest.json?cc=${new Date().getTime()}`;
        // const appManifest = await this.getApplicationManifest(url);
        // if (appManifest) {
        //     appManifest.url = baseUrl;
        //     appManifest.license_expiry_date = expirationDate;
        //     appManifest.available = true;
        //     appManifest.appIcon = await this.getAppIcon(appManifest);
        //     appManifest.url += appManifest.auth_path || '';
        //     return appManifest;
        // }
        // const result = new ApplicationManifest();
        // result.name = '? APP ?';
        // result.url = baseUrl;
        // result.license_expiry_date = expirationDate;
        // result.description = `<p>${baseUrl}</p><p><i class="fas fa-exclamation-circle mr-2" ></i>${'Error'}</p>`;
        // result.available = false;
        // return result;

        return axios.get(url, { timeout: 2000 }).then(async (result) => {
            result.data.url = baseUrl;
            result.data.license_expiry_date = expirationDate;
            result.data.available = true;
            const manifest = result.data as ApplicationManifest;
            manifest.appIcon = await this.getAppIcon(result.data);
            manifest.url += manifest.auth_path || '';
            return manifest;
        }).catch((e) => {
            console.log(e);
            const result = new ApplicationManifest();
            result.name = '? APP ?';
            result.url = baseUrl;
            result.license_expiry_date = expirationDate;
            result.description = `<p>${baseUrl}</p><p><i class="fas fa-exclamation-circle mr-2" ></i>${e}</p>`;
            result.available = false;
            return result;
        });
    }
    private static async getAppIcon(data:ApplicationManifest) : Promise<string> {
        if (data) {
            if (await this.checkIfImageExists(`${data.url}/img/icons/app.svg`)) {
                return `${data.url}/img/icons/app.svg`;
            }
            if (data.icons && data.icons.length > 0) {
                const found = data.icons.find((element) => this.isValid(element));
                if (found) {
                    return `${data.url}/${found?.src}`;
                }
            }
            return './img/icons/icon-48.png';
        }
        return './img/icons/icon-48.png';
    }

    private static async checkIfImageExists(url:string) : Promise<boolean> {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.onload = () => resolve(true);
            img.onerror = reject;
            img.src = url;
        });

    }
    private static isValid(element:ApplicationIcon) : boolean {
        return element.sizes.startsWith('48x') || element.sizes.startsWith('128x') || element.sizes.startsWith('144x') || element.sizes.startsWith('152x');
    }
    public async getBadges(branchId:number, appId:string=''): Promise<{APPID:string, APPAREA:string,COLOR:string,BADGETEXT:string,EXTENSIVETEXT:string}[]>{
        const responseType = "getBadgeMessages_response";
        const sid = this.config.sid();
        const userId = this.config.userId();

        //getBadgeMessages
        if (sid) {
            let data = {
                SID: sid,
                UserID: userId,
                AppID: appId,
                Filiale: branchId
            };

            const response = await axios.post(`${this.config.serviceUrl()}/Common/getBadgeMessages`, data, {timeoutErrorMessage: 'Es ist leider zu einer Zeitüberschreitung gekommen.\nGrund dafür kann eine zu große Datenmenge sein.'});

            if (response.data[responseType]?.ServiceErrors) {
                throw new Error(response.data[responseType].ServiceErrors[0].ERRORRECOMMENDATION);
            }
            // if(!response.data[responseType].BadgeMessages || response.data[responseType].BadgeMessages.length===0) {
            //     return [{
            //         "APPID" : "APRO.ORDER",
            //         "APPAREA" : "BESTELLUNG",
            //         "COLOR" : "#CC4C33",
            //         "BADGETEXT" : "1",
            //         "EXTENSIVETEXT" : "1 Bestellung(en) müssen heute noch komissioniert werden"
            //     },
            //         {
            //             "APPID" : "APRO.ORDER",
            //             "APPAREA" : "LIEFERSCHEIN",
            //             "COLOR" : "#56AD89",
            //             "BADGETEXT" : "1",
            //             "EXTENSIVETEXT" : "1 Lieferschein(e) von heute wurden noch nicht abgerechnet"
            //         }];
            // }
            // console.log(response.data.getLicensedModules_response);
            return response.data[responseType].BadgeMessages;
        }
         return [];
    }
    public async sessionInfo(sid: string, serviceUrl:string): Promise<any> {
        const response = await axios.post(`${serviceUrl}/Authentication/getSessionInformation`,
            {
                SID: sid
            });

        if (response && response.data && response.data.getSessionInformation_response) {
            if (response.data.getSessionInformation_response.ServiceErrors) {
                throw new Error(response.data.getSessionInformation_response.ServiceErrors[0].ERRORDESCRIPTION);
            }else {
                return response.data.getSessionInformation_response;
            }

            // eslint-disable-next-line no-param-reassign

        } else {
            return undefined
        }
    }
}
