/* eslint-disable func-names */
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import router from '@/router';
import Services from '@/assets/js/services/Services';
import axios from "axios";
import store from "@/store/index";

interface UserState {
    userId: null|string;
    branchId: null|string;
    authToken: null|string;
    serviceUrl: null|string;
    loggedIn: boolean;

}

const moduleState: UserState = {
    userId: null,
    authToken: null,
    serviceUrl: null,
    loggedIn: false,
    branchId: null,
};

const moduleGetters: GetterTree<UserState, any> = {
    userId: (state) => function () {
        return state.userId || '';
    },
    authToken: (state) => function () {
        return state.authToken || '';
    },
    serviceUrl: (state) => function () {
        return state.serviceUrl || '';
    },
    branchId: (state) => function () {
        return state.branchId || '';
    },
    isLoggedIn: (state) => function () {
        return state.loggedIn;
    },
};

const moduleMutations: MutationTree<UserState> = {
    setUserId: (state, userId: string|null) => {
        Services.userId = userId;
        state.userId = userId;
    },
    setBranchId: (state, branchId: string|null) => {
        Services.branchId = branchId;
        state.branchId = branchId;
    },
    setAuthToken: (state, authToken: string|null) => {
        Services.authToken = authToken;
        state.authToken = authToken;
    },
    setServiceUrl: (state, serviceUrl: string|null) => {
        Services.serviceUrl = serviceUrl;
        state.serviceUrl = serviceUrl;
    },
    setLoggedIn: (state, loggedIn: boolean) => {
        state.loggedIn = loggedIn;
    },
};

const moduleActions: ActionTree<UserState, any> = {
    // test the url for an auth parameter or redirect to login page
    async login({ commit, getters }) {
        commit('setLoggedIn', false);
        try {
            const query = { ...router.currentRoute.query };
            if (query.auth) {
                const params: any = JSON.parse(atob(query.auth as string));
                commit('setUserId', params.userId);
                commit('setBranchId', params.branchId);
                commit('setAuthToken', params.sid);
                commit('setServiceUrl', params.service);

                delete query.auth;
                await router.replace({ query });
            }
        } catch (e) {
            // ignore, redirect to login
        }

        // update params in Service
        Services.userId = getters.userId();
        Services.authToken = getters.authToken();
        Services.serviceUrl = getters.serviceUrl();
        Services.branchId = getters.branchId();

        const loginSuccessfull: boolean = await Services.Auth.testCredentials();
        commit('setLoggedIn', loginSuccessfull);
        if (!loginSuccessfull) {
            // navigate to login
            const loginServiceDomain = process.env.VUE_APP_LOGIN_URL;
            const app = process.env.VUE_APP_APPID;
            const redirect = window.location.href;
            window.location.href = `${loginServiceDomain}/login?app=${app}&redirect=${redirect}`;
        }
    },

    logout({ commit }) {
        commit('setUserId', null);
        commit('setAuthToken', null);
        commit('setBranchId', null);
        commit('setLoggedIn', false);

        const loginServiceDomain = process.env.VUE_APP_LOGIN_URL;
        const app = process.env.VUE_APP_APPID;
        const redirect = window.location.href;
        window.location.href = `${loginServiceDomain}/logout?app=${app}&redirect=${redirect}`;
    },
};

const users: Module<UserState, any> = {
    namespaced: false,
    state: moduleState,
    getters: moduleGetters,
    mutations: moduleMutations,
    actions: moduleActions,
};

export default users;
