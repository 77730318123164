
import {Component, Prop, Vue} from "vue-property-decorator";
import Services from "@/assets/js/services/Services";
import store from "@/store";

@Component({components: {}})
export default class StartApp extends Vue {
    private errorMessage:string="Leider hat etwas nicht funktioniert."
    private success: boolean = true;

    async mounted() {
        const query = {...this.$route.query};

        Services.serviceUrl = store.getters.serviceUrl();
        if (query.sid ) {
            if(query.surl && query.surl !== ''){
                console.log(query.surl);
                const urlString = query.surl.toString();
                if(this.isUrlValid(urlString)) {
                    this.$store.commit("setServiceUrl", urlString);
                    Services.serviceUrl = store.getters.serviceUrl();
                }else {
                    console.log("not a valid url: " + urlString )
                }
            }
            try{
                const sessionInfo = await Services.Apps.sessionInfo(query.sid.toString(), this.$store.getters.serviceUrl());
                if(sessionInfo){
                    this.$store.commit("setAuthToken", query.sid.toString());
                    this.$store.commit("setBranchId", sessionInfo.Filiale.ID);
                    this.$store.commit("setUserId", sessionInfo.User.ID);
                    this.$store.commit("setLoggedIn", true);
                    await this.$router.replace("/");
                    this.success = true;
                }else {
                    this.success = false;
                }
            }catch(e:any) {
                console.log(e);
                this.success = false;
                this.errorMessage = e;
                //await this.$store.dispatch('logout');
            }

        } else {
            this.success = false;
            await this.$store.dispatch('login');
        }
    }
    private isUrlValid(url:string):boolean {
        try {
            const newUrl = new URL(url);
            return newUrl.protocol === 'http:' || newUrl.protocol === 'https:';
        } catch (err) {
            return false;
        }
    }
}