
import { Component, Vue } from 'vue-property-decorator';
import GetLicenceInformationResponseEntry from '@/assets/js/models/GetLicenseInformationResponseEntry';
import Services from '@/assets/js/services/Services';

@Component
export default class ServiceAbout extends Vue {
    private serviceAbout: GetLicenceInformationResponseEntry;
    private serviceUrl:string|null;

    constructor() {
        super();
        this.serviceAbout = new GetLicenceInformationResponseEntry();
        this.serviceUrl = Services.serviceUrl;
    }

    getText(text: string): string {
        if (text) {
            return text
                .replace(/;/g, '\n');
        }
        return '';
    }

    async created() : Promise<void> {
        this.serviceAbout = await Services.Licensing.getLicenseInfo();
    }
}
