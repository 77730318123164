import ApplicationIcon from '@/assets/js/models/ApplicationIcon';

export default class ApplicationManifest {
    public appIcon!:string;

    public url!: string;

    // eslint-disable-next-line camelcase
    public license_expiry_date !: string;

    public name !: string;

    // eslint-disable-next-line camelcase
    public manifest_version !: number;

    // eslint-disable-next-line camelcase
    public short_name!: string;

    // eslint-disable-next-line camelcase
    public start_url!: string;
    public display!: string;
    // eslint-disable-next-line camelcase
    public background_color!: string;
    public version!: string;
    public description!: string;
    // eslint-disable-next-line camelcase
    public theme_color!: string;
    public icons?: ApplicationIcon[] | null;
    // eslint-disable-next-line camelcase
    public auth_path!: string;

    public available!:boolean;

}
